/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { React, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import axios from 'src/utils/axios';

import { useTranslate } from 'src/locales';

import Logo from 'src/components/logo';

import PaymentSummary from './payment-summary';
import PaymentBillingAddress from './payment-billing-address';

// ----------------------------------------------------------------------
InstallService.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default function InstallService({ title, subheader, ...other }) {
  const { t } = useTranslate();
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [method, setMethod] = useState('');
  const [producto, setProducto] = useState([]);
  const [productoName, setProductoName] = useState('');
  const handleChangeMethod = useCallback((newValue, productsList) => {
    setMethod(newValue);
    setProducto(newValue);
    setProductoName(productsList);
  }, []);

  const handleCreateSession= async () => {
    await axios
      .post(`/api/stripe/create_checkout_session?key=${method.id}`, Request)
      .then((res) => {
        
      })
      .catch((error) => {});
  };
  const handlePortalSession= async () => {
    await axios
      .post(`/api/stripe/create_portal_session`, {
        session_id: sessionId
      })
      .then((res) => {
        
      })
      .catch((error) => {});
  };
  const SuccessDisplay = () => (
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      sx={{ height: '50vh',
        marginRight:'120px'
      }} 
    >
      <Logo
        sx={{
          zIndex: 9,
          flexDirection: 'column',
          position: 'relative',
          mb: 4,
        }}
      />
      <Typography variant="h5" component="h3">
        {t('payments.subrealizada')}
      </Typography>
        <Button
          id="checkout-and-portal-button"
          type="submit"
          variant="contained"
          onClick={handlePortalSession}
          color="primary"
          sx={{ mt: 3, borderRadius: 2 }}
        >
          {t('payments.manage')}
        </Button>
    </Stack>
  );
  useEffect(() => {

  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  return success && sessionId !== '' || sessionId !== '' ? (
    <SuccessDisplay sessionId={sessionId} />
  ) : (
      <Container
        sx={{
          pt: 14,
          pb: 8,
          minHeight: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          rowSpacing={{ xs: 5, md: 0 }}
          columnSpacing={{ xs: 0, md: 4 }}
          justifyContent="center"
        >
          <Grid item xs={12} md={8}>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
              sx={{
                p: { md: 5, xs: 3 },
                borderRadius: 2,
                border: (theme) => ({
                  md: `dashed 0.5px ${theme.palette.divider}`,
                }),
                maxWidth: '600px',
              }}
            >
              <PaymentBillingAddress selectedMethod={method} onChangeMethod={handleChangeMethod} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <PaymentSummary producto={producto} products={productoName} onclick={handleCreateSession}/>
          </Grid>
        </Grid>
      </Container>
  );
}
