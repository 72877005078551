const cat = {
  demo: {
    title: 'Català',
    introduction: '',
  },
  resetpass: {
    mesage: 'Has oblidat la contrasenya?',
    info: 'Introdueixi la seva adreça de correu electrònic associada al seu compte i li enviarem un enllaç per restablir la seva contrasenya.',
    reset: 'Reiniciar',
    back: 'Enrere',
    sent: 'Sol·licitud enviada amb èxit',
    confirmation: 'Hem enviat un correu electrònic de confirmació a',
    check: 'Comprova el teu correu electrònic',
    check1:
      'Hem enviat per correu electrònic un codi de confirmació de 6 dígits, si us plau introdueixi el codi al quadre de sota per verificar el seu correu electrònic.',
    verify: 'Verificar',
    resend: 'Reenviar codi',
    donthave: 'No tens el codi? ',
    return: 'Tornar a la Pàgina Principal',
    success: 'Sol·licitud enviada amb èxit!',
    success2: 'Li hem enviat un missatge de confirmació de 6 dígits al seu correu electrònic.',
    success3:
      'Si us plau, introdueixi el codi al quadre de sota per verificar el seu correu electrònic.',
    newpass: 'Actualitzar Contrasenya',
    confirmpass: 'Confirmar Nova Contrasenya',
    pass: 'Contrasenya',
     checkpegatina : "Consulta l'adhesiu físic",
    checkpegatina2 : "Per garantir la seguretat de les vostres dades sensibles, el PIN de confirmació de 6 dígits està imprès a l'adhesiu físic. Introduïu aquest PIN a la casella de sota per assegurar l'accés a la informació confidencial. Si no disposeu del PIN, inicieu sessió i no caldrà introduir-lo.",
    errorpin: 'Pin erroni'
  },
  newparameter: {
    back: 'Enrere',
    next: 'Següent',
    scope: 'Abast del Paràmetre',
    type: 'Tipus de Paràmetre',
    config: 'Configuració',
    posology: 'Venent el prescrit en Posologia',
    swap: 'Substitució de Prescripció',
    notdispense: 'No dispensació',
    priority: 'Prioritzar Destacats Farmàtic',
    add: 'Finalitzar',
    priorityconfirm: 'A partir dara donarem prioritat als teus Medicaments Destacats.',
    posologyconfirm: 'A partir dara només es vendrà el prescrit en Posologia.',
    selectsust: 'Prescripció a Substituir',
    selectsust2: 'Prescripció a Dispensar.',
    notdispence2: 'Seleccioneu la prescripció que no voleu dispensar.',
    number: 'Número',
    scopetable: 'Afecta a',
    name: 'Nom',
    typeparameter: 'Tipus de Paràmetre',
    code: 'Codis Nacionals',
    patient: 'Pacient',
    center: 'Centre',
    pharmacy: 'Farmàcia',
    swaptype: 'Substitució de Prescripció',
    prioritytype: 'Prioritzar Destacats',
    posologytype: 'Només el prescrit en Posologia',
    notdispencetype: 'No dispensar',
    no_financied: 'Només Finançats',
    no_narcotic: 'No vendre Estupefaents',
    maxstock: 'Major Estoc',
    max_box_dps: 'Unitats Màximes',
    no_regex: 'No dispensació per "paraula"',
    order_lab: 'Prioritzar Laboratori',
    check_stock: 'Vendre amb Estoc',
    force_posology: 'Venent pels CN de Posologia',
    laboratory: 'Llistat de Laboratoris',
    replace_max_stock: 'Substitució per Màxim Estoc',
    cst_change: 'Última Recàrrega a Tolva',
  },
  table: {
    column: 'Columnes',
    find: 'Troba la teva columna.',
    columntitle: 'Títol de la columna',
    hyde: 'Desmarca totes',
    mark: 'Selecciona totes',
    filters: 'Filtres',
    density: 'Format de Visualització',
    density1: 'Compacte',
    density2: 'Normal',
    density3: 'Gran',
    export: 'Exportar',
    operators: 'Operadors',
    contains: 'conté',
    equals: 'és igual a',
    start: 'comença per',
    end: 'acaba per',
    empty: 'està buit',
    noempty: 'no està buit',
    value: 'Valor',
    filtervalue: 'Filtrar valor',
    download: 'Descarregar com a CSV',
    print: 'Imprimir',
    download2: 'Descarregar com a Excel',
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnes',
    columnMenuManageColumns: 'Gestionar columnes',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Amagar columna',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar per ASC',
    columnMenuSortDesc: 'Ordenar per DESC',
  },
  menu: {
    general: 'MENÚ',
    order: 'Gestió de Comandes',
    'historic Orders': 'Històric',
    catalog: 'Medicaments',
    patient: 'Pacients',
    posology: 'Posologia',
    newpro: 'Producció',
    Laboratorys: 'Laboratoris',
    Config: 'Configuració',
    management: 'ADMINISTRACIÓ',
    user: 'Usuaris',
    profile: 'El meu perfil',
    list: "Administració d'Usuaris",
    myinstallation: 'La meva instal·lació',
    subscription: 'La meva farmàcia',
    invoice: 'Factura',
    centers: 'Centres',
    centersmanage: 'Gestió de Centres',
    virtual: 'IA Mery',
    available: 'Comandes de compra',
    parameters: 'Paràmetres',
    listrx: 'Receptes per Pacient',
    reports: 'Recepta Electrònica',
    traceability: 'Traçabilitat',
    alerts: 'Alertes',
    history: 'Històric',
    medpatient: 'Medicació per Pacient',
    merge: 'Cruïlla Posologia',
    take: 'Entrega de Medicació',
    import: 'Importació',
    inicio: 'Inici',
    upload: 'Importar Fitxer',
    parametersimport: "Paràmetres d'Importació",
    task: 'Tasques Programades',
    electronic: 'Targetes Sanitàries',
    availableia: 'Disponibles',
    match: 'Cruïlla Posologia /Recepta',
    sellparameters: 'Paràmetres de Venda',
    form0: 'Informes',
    form1: 'Informe de Producció',
    form2: 'Medicació per Pacient',
    form3: 'Traçabilitat',
    chat: 'Xat',
    calendar: 'Calendari',
    support: 'Suport Tècnic',
    assist: 'Assistència',
  },
  dropdown: {
    Home: 'Inici',
    Profile: 'Perfil',
    Settings: 'Configuració',
    logout: 'Tancar sessió',
  },
  alert: {
    success: 'Accés concedit',
    newuser: 'Nou Usuari',
    verified: 'Verificat',
    yes: 'Sí',
    no: 'No',
    edit: 'Editar',
    deleteuser: 'Eliminar Usuari',
    confirmation: 'Estàs segur que vols eliminar aquest/s Usuari/s?',
  },
  myuser: {
    general: 'General',
    pass: 'Canviar Contrasenya',
    allow: 'Permesos',
    sice: 'Mida màxima de',
    save: 'Desar Canvis',
    about: 'Comentari',
    old: 'Contrasenya Actual',
    new: 'Nova Contrasenya',
    mustbe: 'La contrasenya ha de tenir com a mínim 6 caràcters.',
    confirm: 'Confirmar Nova Contrasenya',
    banned: 'Desactivat',
    applyban: 'Aplicar desactivació del compte.',
    savechanges: 'Desar Canvis',
    upload: 'Pujar Fitxer',
    photo: 'Pujar Foto',
    update: 'Actualitzar Foto',
  },
  register: {
    mesage: 'Ràpid, senzill i intuitiu.',
    intro: 'Crea el teu compte i comença a gaudir de les nostres solucions.',
    intro2: 'És molt senzill!',
    name: 'Nom i Cognoms',
    lastname: 'Cognoms',
    namepharma: 'Nom de la Instal·lació',
    create: 'Registrar-se',
    already: 'Ja tens un compte? ',
    firstnamerec: 'El nom és obligatori',
    lastnamerec: 'Els cognoms són necessaris',
    emailrec: 'El correu electrònic és obligatori',
    passrec: 'La contrasenya és necessària',
  },
  centers: {
    list: 'Llistat de Centres',
    title: 'Fitxa del centre',
    nif: 'NIF',
    date: 'Data de registre',
    production: 'Mode Producció',
    machinepro: 'Dispositiu Seleccionat',
    print1: 'Imprimir els NO envasables.',
    print2: 'Imprimir per moments.',
    print3: 'Imprimir els Si cal.',
    code: 'Per codi de medicament',
    code2: 'Per família d’envasos',
    code3: 'Per famílies biològiques',
    change: 'Voleu canviar de Centre?',
    changeinstallation: 'Voleu canviar d’Instal·lació?',
    sure: 'Esteu segur que voleu canviar de centre?',
    acept: 'Acceptar',
    addnew: 'Nova Pauta Predeterminada',
    namenewpred: 'Nom de la Pauta',
    syncdate: 'Sincronitzar Data Final Recepta Electrònica',
  },
  listmedicine: {
    medication: 'Medicació',
    search: 'Cerca',
    filter: 'Filtre Medicament',
    all: 'Tots',
    tipemedication: 'Medicaments',
    familya: 'Família Envas',
    familyb: 'Família Biològica',
    inactive: 'Inactiu',
    active: 'Actiu',
    ficha: 'Fitxa del Medicament',
    brand: 'Marca',
    generic: 'Genèric',
    needrecipe: 'Recepta necessària',
    code: 'Codi',
    name: 'Nom',
    codefamily: 'Codi Família Biològica',
    namefamily: 'Nom Família Biològica',
    units: 'Unitats Caixa',
    atc: 'Codi ATC',
    packable: 'Envasable',
    psicotropic: 'Psicotrópic',
    narcotic: 'Narcòtic',
    warning: 'Precaucions',
    replaceable: 'Reemplaçable',
    clinic: 'Envas Clínic',
    use: 'Ús Hospitalari',
    comer: 'Comercialitzat',
    tld: 'TLD',
    para: 'Parafarmàcia',
    control: 'Especial Control Mèdic',
    drive: 'Efectes Conducció',
    orphan: 'Òrfà',
    recipe: 'Recepta',
    bio: 'Bio Similar',
    save: 'Desar',
    cancel: 'Cancel·lar',
  },
  listpatient: {
    title: 'Llistat de Pacients',
    search: 'Cerca',
    active: 'Actiu',
    ficha: 'Fitxa del Pacient',
    name: 'Nom',
    dni: 'DNI/NIE',
    born: 'Data Naixement',
    ss: 'Número de la SS',
    phone: 'Telèfon',
    sex: 'Gènere',
    female: 'Femení',
    male: 'Masculí',
    bed: 'Llit',
    region: 'Província',
    room: 'Habitació',
    address: 'Adreça',
    country: 'País',
    city: 'Ciutat',
    cp: 'CP',
    mail: 'Correu electrònic',
    save: 'Desar',
    cancel: 'Cancel·lar',
    sync: 'Sincronització',
    journal: 'Històric de Medicació',
    electronic: 'Recepta Electrònica',
    group: 'Grup',
    doctorname:'Nom del metge/essa'
  },
  production: {
    status: 'Estat',
    title: 'Nova Producció',
    code: 'Codi',
    datecreate: 'Data de Creació',
    dateproduction: 'Data de Producció',
    lastprox: 'Propera/Última Dispensació',
    initialdose: 'Inici/Última Dispensació',
    dateend: 'Data de Finalització',
    cancel: 'Cancel·lar',
    pendent: 'Pendent',
    working: 'En curs',
    produced: 'Produïda',
    deleted: 'Eliminada',
    repackaging: 'Producció de Reenvasament',
    pouch: 'Núm. de Dosis',
    dose: 'Unitats per Dosi',
    printlabel: "Impressió d'Etiquetes",
    labelsearch: 'Buscar per Nom',
  },
  dashboard: {
    title: 'Benvingut de nou 👋',
    titlecomment: 'Amb la nostra avançada IA, oferim el servei més segur i fiable del mercat.',
    discover: 'Descobreix més',
    patients: 'Pacients',
    medication: 'Medicació Activa',
    production: 'Produccions',
    caducity: 'Medicació propera a la caducitat',
    data: "Dades de l'Última Producció: ",
    total: 'Total Dispensat:',
    packable: 'Envasables',
    unpackable: 'No envasable',
    dispensed: 'Dispensat',
    activpatient: 'Pacients Actius',
    activposology: 'Tractaments Actius',
    totalcount: 'U. Dispensades Mes',
    type: 'Característiques',
    if_needed: 'Si precís',
    iaquality: 'IA Qualitat',
    merge: 'Correctes',
    rxx: 'No Recepta',
    posology: 'No Posologia',
    reviwer: 'Revisió',
    dep: 'Diposit',
    ranking: 'TOP Canvis',
  },
  posology: {
    force_print: 'Només Impressió',
    no_sync: 'Protegit',
    title: 'Posologia',
    search: 'Cerca',
    active: 'Actiu',
    inactive: 'Medicació Inactiva',
    save: 'Desar',
    cancel: 'Cancel·lar',
    packable: 'Envasable',
    unpackable: 'No envasable',
    start: "Data d'inici",
    end: 'Data Final',
    dosis: 'Dosi',
    period: 'Dies Període',
    cronic: 'Crònic',
    days: 'Dies',
    hour: 'Hora Període',
    starthour: 'Hora Inici',
    months: 'Mesos',
    january: 'Gener',
    february: 'Febrer',
    march: 'Març',
    april: 'Abril',
    may: 'Maig',
    june: 'Juny',
    july: 'Juliol',
    august: 'Agost',
    september: 'Setembre',
    october: 'Octubre',
    november: 'Novembre',
    december: 'Desembre',
    week: 'Setmanal',
    ciclica: 'Cíclica Dies',
    especilavar: 'Variable',
    ciclicb: 'Cíclica Hores',
    month: 'Mensual',
    aparte: 'Envasat Apart',
    optional: 'Si Precís',
    irreplaceable: 'Irremplazable',
    moment: 'Moment',
    monday: 'Dilluns',
    tuesday: 'Dimarts',
    wednesday: 'Dimecres',
    thursday: 'Dijous',
    friday: 'Divendres',
    saturday: 'Dissabte',
    sunday: 'Diumenge',
    add: 'Afegir Presa',
    name: 'Nom de la presa',
    selecttitle: 'Selecció del Medicament',
    select: 'Seleccionar',
    addtoma: 'Afegir',
    cannot: 'No pots desar una pauta buida.',
    confirmation: 'Confirmació',
    confirmationtext: 'Esteu segur que voleu eliminar aquest tractament?',
    confirmationdelete: 'Eliminar',
    update: 'Canvis desats correctament.',
    error: 'Error en eliminar la posologia.',
    erroradd: 'Error en afegir la posologia.',
    addsuccess: 'Afegit correctament.',
    deletesuccess: 'Eliminat correctament.',
    errordate: 'La Data Inici no pot ser inferior a avui.',
    exist: 'Presa ja existent.',
    periodless: 'El període no pot ser inferior a dies.',
    daysless: 'Dies no pot ser inferior a 1.',
    hourless: 'Hores no pot ser inferior a 1.',
    hourerror: 'El període no pot ser superior a 24 hores.',
    dayerror: 'El dia no pot ser superior a 31 o inferior a 0.',
    delete: 'Eliminar Posologia',
    note: 'Notes',
    interaction: 'Interaccions farmacològiques',
    deletedose: 'Voleu eliminar el tractament seleccionat?',
  },
  login: {
    mesage: 'Hola, benvingut',
    introduction:
      'Lorem Ipsum és simplement text de farciment de la indústria de la impressió i composició tipogràfica. Lorem Ipsum ha estat el text estàndard de la indústria des de la dècada de 1500, quan un impressor desconegut va prendre una galleda de tipus i la va barrejar per fer un llibre de mostres tipogràfiques. Ha sobreviscut no només cinc segles, sinó també el salt a la composició electrònica, romànticament sense canvis. Es va popularitzar en els anys 60 amb el llançament de fulls Letraset que contenien passatges de Lorem Ipsum, i més recentment amb programari de publicació de text com Aldus PageMaker que inclou versions de Lorem Ipsum.',
    signin: 'Inicia sessió a IDOOSE',
    details: 'Introdueix les teves dades a continuació.',
    inicio: 'Inicia Sessió',
    agree: 'En registrar-me, accepto els ',
    terms: 'Termes del servei',
    privacy: 'Política de privacitat',
    and: ' i ',
    account: 'Nou Usuari? ',
    started: 'Crea un compte',
    email: 'Correu electrònic',
    password: 'Contrasenya',
    rememberme: "Recorda'm",
    forgotpassword: 'Has oblidat la teva contrasenya?',
    requiremail: 'El correu electrònic és necessari',
    requirepass: 'La contrasenya és necessària',
    requirement: 'En registrar-me, accepto els ',
    requirement2: "Termes i Condicions d'Ús",
    requirement3: ' i la ',
    requirement4: 'Política de Privacitat',
  },

  virtual: {
    list: 'Llistat de Receptes',
    new: 'Nova Recepta',
    active: 'Activa',
    expired: 'Caducada',
    expiredate: 'Data de Caducitat',
    renewdate: 'Data de renovació',
    raw: 'Banda / Xip / TSI / Núm. Recepta',
    order: 'Codi de Barres (només CAT)',
    review: 'Revisió',
    assignment: 'Assignació de Codis',
    sell: 'Venda',
    create: 'Crear Ordre',
    title: 'Tasques Programades',
    number: 'Núm. Ordre',
    status: 'Estat',
    date: "Data d'Inici",
    patients: 'Núm. de Pacients',
    titleparameters: 'Paràmetres de Venda',
    confirm: 'Vols esborrar la recepta seleccionada?',
    deletesinc: 'Vols esborrar la sincronització seleccionada?',
    productionform: 'Informe de Producció',
    prepareinform: 'Preparar Informe',
    export: 'Exportar a Excel',
  },

  trazability: {
    ema: 'Agència Europea de Medicaments (EMA)',
    text: "L'Agència Europea de Medicaments (EMA) és una agència descentralitzada de la Unió Europea (UE) responsable de l'avaluació científica, la supervisió i el seguiment de la seguretat dels medicaments a la UE. L'EMA és una organització de xarxes les activitats de la qual impliquen milers d'experts d'arreu d'Europa. Aquests experts porten a terme la tasca dels comitès científics de l'EMA.",
    discover: 'Descobreix EMA',
    detections: 'Alertes Detectades',
    eligible: 'Alertes Subsanables',
    lastupdate: 'Última Actualització',
    totals: 'Medicació total Verificada',
  },

  reportpatientproductionlist: {
    lng_title: 'INFORME DE PACIENTS PRODUÏTS',
    lng_titlelistcenter: 'LLISTAT DE PACIENTS',
    lng_phone: 'Telèfon',
    lng_mail: 'Correu',
    lng_num_production: 'Número Producció',
    lng_center: 'Centre',
    lng_date_int: 'Data Inici',
    lng_date_end: 'Data Final',
    lng_code: 'Codi',
    lng_name: 'Nom',
    lng_send: 'Enviament',
    lng_reception: 'Recepció',
    lng_date: 'Data',
    lng_no_medication: 'Sense Mediació',
    lng_reviwer: 'Revisat per:',
    lng_produced: 'Produït per:',
  },
  reportmedicineproductionlist: {
    lng_title: 'INFORME DE MEDICACIÓ AGRUPADA',
    lng_title2: 'INFORME DE RECARREGA',
    lng_title3: 'INFORME DE MEDICACIÓ PER PACIENT',
    lng_phone: 'Telèfon',
    lng_mail: 'Correu',
    lng_num_production: 'Núm. Producció',
    lng_center: 'Centre',
    lng_date_int: 'Data Inici',
    lng_date_end: 'Data Final',
    lng_code: 'Codi',
    lng_name: 'Nom',
    lng_qty: 'Unitats',
    lng_yes: 'Sí',
    lng_no: 'No',
    lng_packable: 'Emblistable',
    lng_date: 'Data',
    lng_dev: 'Tolva',
    lng_lot: 'Lot',
    lng_exp: 'Caducitat',
    lng_qty2: 'Resta',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'MEDICACIÓ NO EMBLISTABLE',
    lng_title_packing: 'MEDICACIÓ EMBLISTABLE',
    lng_mo2: 'DILLUNS',
    lng_tu2: 'DIMARTS',
    lng_we2: 'DIMECRES',
    lng_th2: 'DIJOUS',
    lng_fr2: 'DIVENDRES',
    lng_sa2: 'DISSABTE',
    lng_su2: 'DIUMENGE',

    lng_title: 'PLA FARMACOLÒGIC',
    lng_phone: 'Telèfon',
    lng_mail: 'Correu',
    lng_date: 'Data',
    lng_num_patient: 'Núm. Pacient',
    lng_name_patient: 'Nom',
    lng_dni: 'DNI',
    lng_ss: 'Número SS',
    lng_code_medicine: 'Codi',
    lng_name_medicine: 'Nom Medicament',
    lng_posology: 'Pauta',
    lng_date_int: 'Data Inici',
    lng_date_end: 'Data Final',
    lng_cronic: 'Crònic',
    lng_mo: 'Dl',
    lng_tu: 'Dm',
    lng_we: 'Dc',
    lng_th: 'Dj',
    lng_fr: 'Dv',
    lng_sa: 'Ds',
    lng_su: 'Dg',
    lng_day: 'Dies',
    lng_month: 'Mesos',
    lng_ja: 'Ge',
    lng_fe: 'Fe',
    lng_ma: 'Mç',
    lng_ap: 'Ab',
    lng_my: 'Ma',
    lng_jn: 'Ju',
    lng_jl: 'Jl',
    lng_au: 'Ag',
    lng_se: 'Se',
    lng_oc: 'Oc',
    lng_no: 'No',
    lng_de: 'De',
    lng_month_all: 'Tots Els Mesos',
    lng_hour: 'Cada',
    lng_hour_x: 'Hores Des De Les',
  },

  reportchangeslist: {
    lng_title_not_packing: 'MEDICACIÓ NO EMBLISTABLE',
    lng_title_packing: 'MEDICACIÓ EMBLISTABLE',
    lng_mo2: 'DILLUNS',
    lng_tu2: 'DIMARTS',
    lng_we2: 'DIMECRES',
    lng_th2: 'DIJOUS',
    lng_fr2: 'DIVENDRES',
    lng_sa2: 'DISSABTE',
    lng_su2: 'DIUMENGE',

    lng_title: 'INFORME DE CANVIS',
    lng_phone: 'Telèfon',
    lng_mail: 'Correu',
    lng_date: 'Data',
    lng_num_patient: 'Núm. Pacient',
    lng_name_patient: 'Nom',
    lng_dni: 'DNI',
    lng_ss: 'Número SS',
    lng_code_medicine: 'Codi',
    lng_name_medicine: 'Nom Medicament',
    lng_posology: 'Pauta',
    lng_date_int: 'Data Inici',
    lng_date_end: 'Data Final',
    lng_cronic: 'Crònic',
    lng_mo: 'Dl',
    lng_tu: 'Dm',
    lng_we: 'Dc',
    lng_th: 'Dj',
    lng_fr: 'Dv',
    lng_sa: 'Ds',
    lng_su: 'Dg',
    lng_day: 'Dies',
    lng_month: 'Mesos',
    lng_ja: 'Ge',
    lng_fe: 'Fe',
    lng_ma: 'Mç',
    lng_ap: 'Ab',
    lng_my: 'Ma',
    lng_jn: 'Ju',
    lng_jl: 'Jl',
    lng_au: 'Ag',
    lng_se: 'Se',
    lng_oc: 'Oc',
    lng_no: 'No',
    lng_de: 'De',
    lng_month_all: 'Tots Els Mesos',
    lng_hour: 'Cada',
    lng_hour_x: 'Hores Des De Les',
  },

  sync: {
    new: 'Afegir Sincronització',
  },

  reportpatientjournallist: {
    lng_title: 'INFORME DE DEUTE PACIENTS',
    lng_phone: 'Telèfon',
    lng_mail: 'Correu',
    lng_date: 'Data',
    lng_num_patient: 'Núm. Pacient',
    lng_name_patient: 'Nom',
    lng_dni: 'DNI',
    lng_ss: 'Número SS',
    lng_code_medicine: 'Codi',
    lng_name_medicine: 'Nom Medicament',
    lng_prod: 'Produït',
    lng_seller: 'Venut',
    lng_aport: 'Aportat',
    lng_total: 'Total',
  },
  reportpatientconsent: {
    lng_anx_1:
      'vull participar en el programa de sistemes de dosificació personalitzada de la farmàcia',
    lng_anx_2:
      "Entenc que aquest programa consisteix en l'acondicionament de la medicació en un sistema de dosificació personalitzada.",
    lng_anx_3:
      "Participo de forma totalment voluntària en aquest programa i podré deixar de participar en aquest programa quan ho consideri oportú simplement revocant aquest consentiment complimentant l'apartat revocació del mateix.",
    lng_anx_4:
      "Participar en aquest projecte implica, si es fa a partir d'envasos comercials, deixar els medicaments en els seus envasos originals a la farmàcia on és responsabilitat del farmacèutic la seva correcta custòdia i conservació.",
    lng_anx_5: 'Em comprometo a:',
    lng_anx_51: 'Comunicar al farmacèutic qualsevol variació en la meva medicació.',
    lng_anx_52: 'Complir les condicions de conservació i seguretat del blíster.',
    lng_anx_53:
      "Entregar els dispositius buits de les setmanes anteriors per a la comprovació del compliment i possibles errors d'utilització.",
    lng_anx_6: 'El farmacèutic es compromet a:',
    lng_anx_61: 'Comunicar-me qualsevol problema relacionat amb el medicament que pogués detectar.',
    lng_anx_62:
      'Subministrar-me la informació necessària per a la correcta utilització dels dispositius de dosificació personalitzada i dels propis medicaments.',
    lng_anx_7:
      'Autoritzo al farmacèutic al tractament de les meves dades personals necessàries per a la participació en el programa*.',
    lng_consent: 'CONSENTIMENT INFORMAT',
    lng_date: 'Data',
    lng_dni: 'DNI',
    lng_don_d: 'D/Dª',
    lng_lopd_1:
      "*Les dades personals facilitades per a la inclusió en el programa sistemes de dosificació personalitzada seran inclosos en un fitxer denominat SDP, degudament inscrit a l'Agència Espanyola de Protecció de Dades, el responsable és la farmàcia",
    lng_lopd_2:
      "compten amb totes les mesures de seguretat necessàries d'acord amb la Llei de Protecció de Dades, seran tractades únicament amb la finalitat de gestionar els dispositius de dosificació personalitzats del pacient, les dades podran ser cedits o comunicats a professionals sanitaris, familiars i/o cuidadors relacionats amb el pacient amb l'objectiu de corroborar el tractament i/o millorar l'adhesió al mateix, en cap cas s'utilitzaran amb finalitats de publicitat o promoció comercial, estan sotmesos al secret professional del farmacèutic. Les persones les dades de les quals estan registrades en aquests fitxers tenen dret a accedir a les mateixes, rectificar-les o cancel·lar-les, quan ho considerin oportú dirigint-se a aquesta mateixa farmàcia en els termes previstos legalment.",
    lng_mail: 'Correu',
    lng_name_d: 'Pacient/Representant Legal',
    lng_name_patient: 'Nom',
    lng_name_ph_d: 'Farmacèutic',
    lng_name_ph_sin_d: 'Firma del Farmacèutic',
    lng_name_sin_d: 'Firma del Pacient/Representant Legal',
    lng_num_patient: 'Núm. Pacient',
    lng_phone: 'Telèfon',
    lng_ss: 'Número SS',
    lng_title: 'CONSENTIMENT INFORMAT DEL PACIENT',
  },

  Bob: {
    ordernumber: 'Núm. Ordre',
    status: 'Estat',
    process: 'Progrés',
    Startorder: "Inici de l'Ordre",
    createdate: 'Data de creació',
    selling: 'Processant',
    reviewing: 'Revisant',
    wait: 'En espera',
    sevem: 'SEVEM',
    patient: 'Pacient',
    drug: 'Medicament',
    recipe: 'Recepta Electrònica',
    initialdate: 'Inici del tractament',
    expiredate: 'Caducitat',
    posology: 'Posologia',
    scan: 'Escaneja el Codi',
    dep: 'Diposit',
    without1: 'Sense Posologia',
    without2: 'Sense Recepta',
    reviwer: 'Revisió',
    correct: 'Correcte',
    lot: 'Lot',
    neworder: 'Crear Ordre',
    deleteorder: "Voleu eliminar l'ordre de treball seleccionada?",
    numberorder: "Núm. d'Ordre:",
    history: 'Històric de Traçabilitat',
    scancodes: 'Assignació de Codis',
    forceall: 'Forçar tot a CN',
    last: 'Última Actualització',
    deleteparameter: 'Voleu eliminar el paràmetre seleccionat?',
    sellformtitle: 'INFORME DE VENDA',
  },

  official: {
    error: 'Document no disponible.',
  },

  display: {
    prospectus: 'Prospecte',
    technical: 'Fitxa Tècnica',
    unpackable: 'No envasable',
  },

  notfound: {
    sorry: 'Presa no trobada.',
    sorry2: 'Ho sentim, no hem pogut trobar la pàgina que busca.',
    home: 'Pàgina Principal',
  },
  editproduction: {
    edit: 'Edició de la Producció',
    confirm: 'Esteu segur que voleu modificar la producció?',
    home: 'Pàgina Principal',
    predose: 'Pautes Predeterminades',
    addnewpre: 'Nova Presa Predeterminada',
    nametome: 'Nom de la Presa',
    hourtome: 'Hora de la Presa',
    confirmationtext: 'Esteu segur que voleu eliminar aquesta presa predeterminada?',
  },

  blisterpro: {
    process: 'PROGRÉS',
    sorry2: 'Ho sentim, no hem pogut trobar la pàgina que busqueu.',
    home: 'Pàgina Principal',
  },

  various: {
    exhausted: 'Esgotat',
    datapatient: 'Dades del Pacient',
    center: 'Dades del Centre',
    expiredon: 'Data de Caducitat:',
    synclist: 'Elements Sincronitzats',
    debt: 'Deute',
    drug: 'Medicament',
    produced: 'Produït',
    sell: 'Venut',
    provided: 'Aportat',
    total: 'Total',
    date: 'Data',
    Details: 'Detall',
    event: 'Esdeveniment',
    units: 'Unitats',
    production: 'Producció',
    sell2: 'Venut',
    add: 'Afegir Aportació',
    historic: 'Històric',
    historic2: 'Històric de Traçabilitat',
    ns: 'Núm. Sèrie',
    available: 'Disponible',
    next: 'Properament',
    recommended: 'Recomanat',
    notRecommended: 'No recomanat',
    register: "Registre d'Entregues",
    registerdate: 'Data de la Presa',
    from: 'Des de',
    to: 'Fins a',
    datapicker: "Dates de l'Informe",
    download: 'Descarregar',
    tpills: "Total d'Unitats",
    tneed: 'Restants',
    rbox: 'Caixes',
    result: 'resultats',
    predetermlist: 'Llistat de Pautes',
    deletepredet: 'Voleu eliminar la pauta seleccionada?',
    no_verifi: 'No Verificat',
    verifi: 'Verificat',
    installation: 'La Meva Instal·lació',
    exportamts: 'Exportar ATMs',
    confirmdose: 'Voleu registrar la Presa?',
    whynot: 'Motiu de Cancel·lació',
  },

  tooltip: {
    refill: 'Informe de Recàrrega',
    label: 'Imprimir Etiquetes',
    edit: 'Editar Producció',
    grouped: 'Informe de Medicació Agrupada',
    report1: 'Informe Pacient-Medicament',
    delivery: 'Albarà de Lliurament',
    archived: 'Arxivats',
    delete: 'Eliminar Producció',
    generateproduction: 'Generar Producció',
    repackaging: 'Producció de Reenvasament',
    start: 'Iniciar Producció',
    list: 'Llistat de Pacients',
    add: 'Nou Pacient',
    listposology: 'Posologia per Pacient',
    official: 'Informe Posologia Oficial',
    activate: 'Activar/Desactivar WhatsApp',
    consent: 'Consentiment Informat',
    alllabel: 'Totes',
    changes: 'Informe de Canvis',
    sevem: 'Finalitzar la Venda',
    new: 'Nova Tasca',
    copy: 'Copiar',
    insert: 'Inserir',
    newparameter: 'Nou Paràmetre',
    center: 'Nou Centre',
  },
  import: {
    e_title: "Atenció! L'arxiu importat podria ser incorrecte",
    e_dialog: 'Sembla que hem importat un arxiu de la residència:',
    e_dialog1: 'en lloc de la residència:',
    e_dialog2: 'Si us plau, revisa i verifica la situació.',
    i_title: 'Importar Fitxer',
    i_type: 'Format del Fitxer',
    i_drop: 'Seleccionar Fitxer',
    i_dropb:
      'Pots arrossegar i deixar anar el fitxer aquí o explorar al teu ordinador per seleccionar-lo.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Mòdul no disponible amb la llicència contractada',
    subtitle_: 'Contacteu amb el departament comercial per sol·licitar una demostració!',
    return_: 'Tornar App',
  },
  permit: {
    permit: 'Administrar Permisos',
  },
  permissionsgroup: {
    1: 'Catàleg de Medicaments',
    2: 'Gestió de Pacients',
    3: 'Posologia del Pacient',
    4: 'Mòdul de Producció',
    5: 'Importacions',
    6: 'IA Mery',
    7: 'Traçabilitat',
    8: "Administració d'Usuaris",
    9: 'Administració de Centres',
    10: 'Gestionar dades de la meva Farmàcia',
    11: 'Entrega de Medicació',
    12: 'Informes',
  },
  permissions: {
    1: 'Activar Catàleg de Medicaments',
    2: 'Modificar Medicaments',
    3: 'Visualitzar dades del Pacient',
    4: 'Modificar dades del Pacient',
    5: 'Visualitzar Recepta Electrònica',
    6: 'Afegir o Eliminar Recepta Electrònica',
    7: 'Visualitzar Sincronització',
    8: 'Afegir o Eliminar Sincronitzacions',
    9: 'Visualitzar consums del pacient',
    10: 'Modificar consums del pacient',
    11: 'Activar Posologia',
    12: 'Modificar Posologia',
    13: 'Activar Mòdul de Producció',
    14: 'Crear Producció',
    15: 'Enviar Producció / Apartat Blister Manual',
    16: 'Editar Producció',
    17: 'Eliminar Producció',
    18: "Activar Mòdul d'Importació",
    19: 'Importar fitxers al sistema',
    20: 'Habilitar IA Mery',
    21: 'Visualitzar Tasques Programades',
    22: 'Afegir Tasques',
    23: 'Visualitzar Targetes Sanitàries',
    24: 'Inserir Targetes',
    25: 'Visualitzar Disponibles',
    26: 'Visualitzar Creuament Posologia / Recepta',
    27: 'Activar Paràmetres de Venda',
    28: 'Crear Paràmetre de Venda',
    29: 'Activar apartat de Pacients.',
    30: 'Habilitar mòdul de Control de preses.',
    31: 'Entrega de medicació a Pacients.',
    32: 'Activar Traçabilitat',
    33: "Activar Administració d'Usuaris",
    34: 'Modificar Usuaris',
    35: 'Activar Gestió de Centres',
    36: 'Modificar Centres',
    37: 'Activar La Meva Instal·lació',
    38: 'Modificar La Meva Instal·lació',
    39: 'Activar Informes',
    40: 'Informe de Producció',
  },
  control_takes: {
    1: 'No Precisa',
    2: 'Falta Medicació',
    3: 'Medicació Danyada',
    4: 'El Pacient Es Nega',
    5: 'Altres',
    cause_not_take: 'Motiu',
    6: 'Hora Errònia',
    7: 'Canvi en la Medicació',
    8: 'Afegir',
    9: 'Retirar',
    10: 'Registrar Presa',
  },
  home: {
    home: 'Inici',
    title: 'Simplifica la teva Medicació',
    titletex:
      "Benvingut a la avantguarda de la gestió de medicaments. El nostre Software de Sistemes Personalitzats de Dosificació (SPD) redefinix la manera en què et relaciones amb la teva salut. Oblida't de les complicacions en la preparació dels teus medicaments. Amb la nostra solució intuïtiva i personalitzada, l'adherència al tractament es converteix en una experiència senzilla i eficient.",
    available: 'Disponible a',
    benefits: 'Beneficis del SPD',
    titlebenefit1: 'Avís de Presa',
    benefit1:
      "Optimitza la teva rutina amb la nostra aplicació que et proporciona recordatoris precisos per a cada presa. Oblida't de les preocupacions per oblits i garanteix el teu benestar. A més, consulta les incompatibilitats i interaccions entre els teus medicaments per a una gestió completa i segura de la teva salut.",
    titlebenefit2: 'Servei Personalitzat',
    benefit2:
      'Experimenta un nou estàndard de comoditat en la teva cura de la salut. Amb IDOOSE, rebràs la teva medicació en dosis meticulosament preparades per professionals farmacèutics altament capacitats. Ens dediquem a proporcionar-te una experiència sense complicacions, aportant qualitat i simplificant cada presa.',
    titlebenefit3: 'Preparem la teva Medicació',
    benefit3:
      "Adéu a la complicació de preparar caixes. Amb el nostre sistema d'unidosis correlatives, la presa del teu medicament és tan fàcil com obrir i llest. Cada dosi conté informació detallada, incloent el prospecte, per a oferir-te comoditat i claredat en cada pas del teu tractament.",
    appidoose: 'Amb IDOOSE mai oblidaràs la teva Medicació.',
    appidoosetext:
      "Descarrega l'app, registra't i et posarem en contacte amb la teva Farmàcia més propera.",
    listposology: 'Posologia per Pacient',
    design: 'Dissenyada per a tu',
    partner: 'Vols col·laborar amb nosaltres?',
    partner2: 'FES-TE SOCI',
    partnertext:
      "Ens destaquem com a líders indiscutibles en l'aplicació de la intel·ligència artificial en el Sistema Personalitzat de Dosificació (SPD). En triar-nos, garantiràs l'eficiència de les teves produccions i eliminaràs per complet qualsevol risc d'error en el procés de producció. Confia en la nostra experiència per dur les teves operacions al més alt nivell de precisió i seguretat.",
    prices: 'PREUS I PLANS',
    licence: 'Llicència',
    patient: 'Gestió de Pacients',
    production: 'Mòdul de Producció',
    import: 'Importació de Fitxer Robot',
    blister: 'Producció de Blister Manual',
    update: 'Actualitzacions',
    support: 'Suport Tècnic',
    patient30: 'Fins a 50 Pacients',
    patient31: 'Pacients Il·limitats',
    rx: 'Connexió a Recepta Electrònica',
    inform: 'Informe de Posologia CCAA',
    warning: 'Canvis en Recepta Electrònica',
    next: 'Pròximes Dispensacions',
    mach: 'Cruïlla de Posologia amb Recepta Electrònica',
    sell: 'Mòdul de Venda',
    question: 'Encara tens preguntes?',
    question2:
      "Per oferir-te l'assessorament més adequat, indica'ns si representes una farmàcia, hospital o laboratori. D'aquesta manera, podrem proporcionar-te informació personalitzada segons les particularitats del teu sector.",
    contact: 'Contacta',
    reserved: 'Tots els drets reservats, fet per',
    forgotpasswordintro:
      "Introdueix l'adreça de correu electrònic associada al teu compte i et enviarem un enllaç per restablir la teva contrasenya.",
    request: 'Enviar sol·licitud',
    returnto: 'Tornar a inici de sessió',
    choose: 'Necessites un Robot SPD?',
    choose1: 'Descobreix els nostres Robots SPD',
    choose2:
      "Disposem d'una àmplia gamma de robots SPD per adaptar-nos a les necessitats específiques. Oferim models dissenyats segons el volum de producció, el nivell d'automatització i el format requerit, ja sigui blister o bossa. A més, comptem amb robots equipats amb la tecnologia Oculus, que integren la verificació i la revisió mèdica dins del propi equip.",
    Siglas: 'SPD',
    robottitle: 'Explora la nostra gamma de robots',
    robotdescription:
      "Si estàs buscant optimitzar la automatització en la preparació de SPD, els nostres robots són el teu aliadat ideal. Disposem d'una àmplia gamma de robots SPD, des dels altament automatitzats fins als que requereixen menys automatització, per adaptar-nos a les teves necessitats específiques. Gràcies a la nostra tecnologia avançada i a les tolves universals FSP que oferim, podràs reduir significativament la dependència de la calibració. A més, els nostres robots estan equipats amb el sistema OCULUS integrat, que et proporciona evidència fotogràfica detallada de cada producció, així com anàlisi exhaustius de totes les dosis.",
    catalog: 'Descarregar Catàleg',
    diff: 'Principals avantatges dels nostres robots SPD',
    diff1: 'FSP Universals',
    diff1a:
      'Amb les FSP Universals, podràs utilitzar la medicació que necessitis en el moment precís sense complicacions i sense calibracions. La nostra innovadora tecnologia garanteix la utilització de qualsevol tipus de medicament que desitgis, sense importar la seva forma o mida. Gaudiràs de major llibertat en la teva producció.',
    diff2: 'Alta Rotació',
    diff2a:
      'Per assegurar la disponibilitat constant de les molècules més utilitzades, realitzarem una calibració cuidadosa. Els nostres experts duran a terme un estudi exhaustiu per validar aquesta selecció, garantint així una calibració precisa i una automatització eficient. Aquest enfocament ens permet assolir una automatització òptima.',
    diff3: 'Traçabilitat TOTAL',
    diff3a:
      'Amb els nostres robots, gestionar la teva medicació és més senzill que mai. Només cal escanejar el codi Sevem per registrar el lot i la caducitat en cada dosi produïda. Aquest procés ràpid i eficient et brinda la capacitat de fer un seguiment detallat de la teva medicació, assegurant un control precís i segur de la mateixa.',
    robotnews: 'Sistema OCULUS',
    robotnews2:
      "Des de Robotik, hem desenvolupat la tecnologia OCULUS, que s'encarrega de revisar i analitzar tota la producció mitjançant visió artificial. Amb OCULUS, oblida't del temps dedicat a revisar la producció manualment; la nostra tecnologia ho fa per tu. A més, sempre deixa una evidència fotogràfica de tot el que s'ha produït amb el teu robot Robotik. En resum, OCULUS et brinda tranquil·litat total.",
    descobreix: 'Descobreix les característiques de cada Robot SPD',
    descobreix2: 'SELECCIONA',
    tablerobots: 'El robot SPD ideal per a totes les necessitats',
    footer:
      'La millor solució SPD unidosis del mercat. Som experts en implementar la intel·ligència artificial en els processos de gestió.',
    corfirmform: '¡Formulari rebut!',
    corfirmform2:
      'El nostre equip revisarà la informació proporcionada i ens posarem en contacte amb tu aviat. ¡Gràcies pel teu interès!',
    telèfon: 'Telèfon',
  },
  myprofile: {
    edit: 'Editar',
    user: 'Usuari',
    name: 'Nom i Cognoms',
    mail: 'Correu Electrònic',
    number: 'Número de Telèfon',
    country: 'País',
    state: 'Província',
    city: 'Ciutat',
    address: 'Adreça',
    zip: 'CP',
    company: 'Empresa',
    role: 'Rol',
    save: 'Desar Canvis',
    delete: 'Eliminar Usuari',
    verify: 'Correu Electrònic Verificat',
    verifyinfo:
      "En desactivar aquesta opció, l'usuari rebrà automàticament un correu electrònic de verificació.",
    banned: 'Desactivat',
    apply: 'Desactivar compte',
    new: 'Crear Usuari',
    permit: 'Permesos *.jpeg, *.jpg, *.png, *.gif',
    max: 'mida màx. de',
    management: 'Administració',
    search: 'Cercar...',
    list: "Llistat d'Usuaris",
    changecenter: 'Canvi de Centre',
    resetpass: 'Restablir Contrasenya',
    confirmdelete: "¿Desitges eliminar l'usuari seleccionat?",
    dense: 'Reduir',
    confirmreset: 'Correu electrònic enviat correctament',
    quick: 'Editar Permisos',
  },
  pages: {
    pages: 'Pàgines',
    about: 'Sobre Nosaltres',
    contact: 'Contactar amb IDOOSE',
    inicio: 'Inici',
    info: 'Informació',
    community: 'Comunitat',
    post: 'Publicacions',
    robot: 'Robots SPD',
  },
  about: {
    who: 'Qui som?',
    about: 'Sobre Nosaltres',
    contact: 'Contactar amb IDOOSE',
    inicio: 'Inici',
    info: 'Informació',
    community: 'Comunitat',
    post: 'Publicacions',
    whotitle: 'Qui som?',
    are: 'som?',
    mision: 'La nostra missió és millorar la salut i el benestar dels nostres pacients',
    mision2: 'mitjançant la preparació precisa de medicaments en unitats de dosi, garantint',
    mision3: 'una administració segura i efectiva de manera personalitzada.',
    whatis: 'Què és IDOOSE?',
    text: "Som més que un programa de gestió de medicació, busquem impulsar un canvi positiu en la salut de les persones. Amb el programa de gestió més avançat i accessible, ens esforcem per oferir eines que no només simplifiquin l'administració de medicaments, sinó que també promoguin la connexió comunitària i el benestar general. Gràcies a la nostra IA, oferim el servei més segur i fiable del mercat en la preparació de medicació. A IDOOSE oferim un camí cap a una vida més saludable, senzilla i segura.",
  },
  contact: {
    where: 'On',
    find: 'estem?',
    contact: "No dubtis en posar-te en contacte amb nosaltres. Estarem encantats de atendre't.",
    name: 'Nom',
    email: 'Correu Electrònic',
    subjet: 'Assumpte',
    message: 'Escriu el teu missatge aquí.',
    submit: 'Enviar',
  },
  rulesimport: {
    list: "Paràmetres d'importació",
    form: 'Informe de Vendes',
    step1: 'Seleccionar Fitxer',
    step2: 'Revisió de conflictes',
    step3: 'Pujada del fitxer',
    importcorrect: 'Correcte',
    sugest: 'Recomanació',
    new: 'Nou',
    noconflicts: "Fitxer validat correctament, no s'han detectat conflictes.",
    sureimport:
      'La base de dades ha estat actualitzada, premi finalitzar per completar la importació.',
    importcomplete: "La importació s'ha completat amb èxit.",
  },
  calendar: {
    title: 'Calendari',
    event: 'Nou Event',
    month: 'Mes',
    week: 'Setmana',
    day: 'Dia',
    agenda: 'Agenda',
    today: 'Avui',
    new: 'Afegir Event',
    new2: 'Títol',
    description: 'Descripció',
    allday: 'Tot el Dia',
    start: "Data d'Inici",
    end: 'Data Final',
  },
  tablerobots: {
    tolvas: 'Nombre de ganyes',
    univers: 'FSP Universals',
    width: 'Amplada (cm)',
    height: 'Alçada (cm)',
    depth: 'Profunditat (cm)',
  },
  yup: {
    yup_min: 'Massa curt !',
    yup_max: 'Massa llarg !',
    yup_required: 'Camp Requerit !',
    yup_email: 'El correu electrònic ha de ser una adreça de correu electrònic vàlida !',
    yup_phone: 'El número de telèfon ha de contenir només dígits !',
  },
  cookie_consent: {
    acept: "D'acord",
    reject: 'Rebutjar',
    message:
      'El nostre lloc web utilitza cookies per analitzar el trànsit i millorar els nostres serveis.',
  },
  form_geometric: {
    1: 'Allargat',
    2: 'Ampolla',
    3: 'Anell',
    4: 'Arc',
    5: 'Bala',
    6: 'Biconvex',
    7: 'Càpsula',
    8: 'Cartutx',
    9: 'Cilíndric',
    10: 'Còncau',
    11: 'Quadrat',
    12: 'El·líptica',
    13: 'Esferica',
    14: 'Gas',
    15: 'Inhalador',
    16: 'Llàgrima',
    17: 'Líquid',
    18: 'Poma',
    19: 'Oblongo',
    20: 'Octogonal',
    21: 'Ovalat',
    22: 'Polvo',
    23: 'Rectangular',
    24: 'Rodó',
    25: 'Rombo',
    26: 'Sobre',
    27: 'Triangular',
    28: 'Vial',
  },
  form_size: {
    0: 'Sense Definir',
    1: '2 mm (Micro)',
    2: '4 mm (Petit)',
    3: '8 mm (Mitjà)',
    4: '12 mm (Gran)',
    5: '18.0 mm (Molt Gran)',
    6: '23.3 mm (Extra Gran)',
  },
  form_desc: {
    form_geometric: 'Forma Geomètrica',
    form_size: 'Mida',
    form_slot: 'Ranurat',
  },
  termsconditions: {
    title: "Termes i Condicions d'Ús d'IDOOSE",
    subtitle: 'Benvingut a IDOOSE',
    parraf1:
      "Us agraïm que hagueu triat IDOOSE. Llegiu atentament aquests termes i condicions abans d'utilitzar el nostre programari. En utilitzar IDOOSE, accepteu aquests termes i condicions. Si no esteu d'acord amb algun d'aquests termes, no utilitzeu el nostre programari.",
    subtitle2: '1. Àmbit del Contracte i Acceptació',
    subtitle3: '1.1. Serveis coberts en el contracte',
    parraf2:
      "Aquest contracte s'aplica a idoose.com i a qualsevol altre programari, lloc web o servei proporcionat per IDOOSE ROBOTIK SL (col·lectivament, els 'serveis').",
    subtitle4: "1.2. Acceptació d'aquest contracte",
    parraf3:
      "En utilitzar els serveis d'IDOOSE, accepteu aquests termes i condicions sense modificacions. Si no esteu d'acord, no utilitzeu els serveis.",
    subtitle5: '1.3. Modificació del contracte',
    parraf4:
      "IDOOSE ROBOTIK SL es reserva el dret de modificar aquests termes i condicions en qualsevol moment. Us notificarem de qualsevol canvi mitjançant correu electrònic o a través del nostre lloc web. L'ús continuat dels serveis després d'aquests canvis constitueix la vostra acceptació dels mateixos.",
    subtitle6: '2. Ús dels Serveis i Restriccions',
    subtitle7: '2.1. Ús del programari',
    parraf5:
      'IDOOSE està llicenciat, no venut. Heu de complir totes les restriccions tècniques del programari i no podeu:  •Eludir les restriccions tècniques del programari •Descompilar, desmuntar o realitzar enginyeria inversa del programari, excepte on la llei ho permeti. •Fer més còpies del programari de les especificades en aquest contracte. •Publicar el programari perquè altres el copiïn. •Llogar, arrendar o prestar el programari. •Transferir el programari o aquest contracte a un tercer.',
    subtitle8: "2.2. Obligacions de l'usuari",
    parraf6:
      "Us comprometeu a utilitzar els serveis de manera responsable i a no realitzar cap acció que pugui perjudicar IDOOSE ROBOTIK SL o altres usuaris. Això inclou, però no es limita a: •No utilitzar els serveis per a activitats il·legals o no autoritzades. •No interferir amb el funcionament dels serveis o accedir als serveis mitjançant mètodes no autoritzats. •No recopilar informació personal d'altres usuaris sense el seu consentiment. •No carregar ni distribuir virus ni qualsevol altre tipus de programari maliciós.",
    subtitle9: "3. Compte d'IDOOSE",
    subtitle10: '3.1. Creació de compte',
    parraf7:
      "Per accedir a certs serveis, necessitareu un compte d'IDOOSE. Sou responsable de mantenir la confidencialitat de la vostra informació de compte i contrasenya.",
    subtitle11: '3.2. Ús del compte',
    parraf8:
      'Sou responsable de totes les activitats que es produeixin sota el vostre compte. Heu de notificar a IDOOSE ROBOTIK SL immediatament de qualsevol ús no autoritzat del vostre compte o qualsevol altra violació de seguretat.',
    subtitle12: '4. Contingut',
    subtitle13: '4.1. Propietat del contingut',
    parraf9:
      'El contingut que carregueu o transmeteu mitjançant els serveis segueix sent de la vostra propietat. IDOOSE ROBOTIK SL no reclama la propietat del vostre contingut.',
    subtitle14: '4.2. Responsabilitat del contingut',
    parraf10:
      "Sou l'únic responsable del contingut que carregueu, emmagatzemeu o transmeteu a través dels serveis. Declareu i garanteixeu que teniu tots els drets necessaris per carregar aquest contingut i que el vostre contingut no infringeix els drets de tercers.",
    subtitle15: '4.3. Revisió i eliminació de contingut',
    parraf11:
      'IDOOSE ROBOTIK SL es reserva el dret de revisar el contingut carregat en els seus servidors i eliminar qualsevol contingut que, a la seva discreció, infringeixi aquests termes i condicions o la llei aplicable.',
    subtitle16: '5. Cancel·lació dels Serveis',
    subtitle17: "5.1. Per part de l'usuari",
    parraf12:
      "Podeu cancel·lar el vostre compte d'IDOOSE en qualsevol moment a través de les pàgines d'administració del vostre compte. La cancel·lació entrarà en vigor al final del vostre període de facturació.",
    subtitle18: "5.2. Per part d'IDOOSE ROBOTIK SL",
    parraf13:
      'IDOOSE ROBOTIK SL es reserva el dret de cancel·lar el vostre compte o accés als serveis en qualsevol moment, amb o sense motiu, i sense previ avís.',
    subtitle19: '6. Legislació aplicable',
    parraf14:
      "Aquest contracte es regeix per les lleis d'Espanya, sense donar efecte a cap elecció de principis legals. Qualsevol disputa que sorgeixi en relació amb aquest contracte serà sotmesa a la jurisdicció exclusiva dels tribunals d'Espanya.",
    subtitle20: '7. Dades de contacte',
    parraf15:
      'Per a qualsevol pregunta sobre aquest contracte, podeu posar-vos en contacte amb nosaltres a info@idoose.com.',
  },
  privacy: {
    title: 'Política de Privacitat',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'A IDOOSE ROBOTIK SL, amb domicili a Carrer Castell de Moratalla 45, Lorquí, Múrcia, CP 30564 i CIF B56740137, estem compromesos a protegir la privacitat i seguretat dels nostres usuaris. Aquesta Política de Privacitat descriu com recopilem, usem i protegim la vostra informació personal quan utilitzeu el nostre programari.',
    subtitle2: '1. Informació que Recopilem',
    parraf2:
      "Podem recopilar els següents tipus d'informació: Informació d'identificació personal: Nom, adreça, número de telèfon, adreça de correu electrònic, i qualsevol altra informació que proporcioneu voluntàriament. Informació d'ús del programari: Dades sobre com interactueu amb el nostre programari, incloses les funcions que utilitzeu i la freqüència d'ús. Informació tècnica: Dades tècniques sobre el vostre dispositiu, com l'adreça IP, el tipus de navegador, el sistema operatiu i la configuració d'idioma.",
    subtitle3: '2. Ús de la Informació',
    parraf3:
      "Utilitzem la informació recopilada per a: Proporcionar, operar i mantenir el nostre programari. Millorar, personalitzar i expandir el nostre programari. Entendre i analitzar com utilitzeu el nostre programari. Desenvolupar noves funcions, productes i serveis. Comunicar-nos amb vosaltres, sigui directament o a través d'un dels nostres socis, inclosos per al servei al client, proporcionar-vos actualitzacions i altra informació relacionada amb el programari, i amb fins de màrqueting i promocionals. Processar les vostres transaccions i gestionar el vostre compte. Enviar correus electrònics periòdics.",
    subtitle4: '3. Protecció de la vostra Informació',
    parraf4:
      "Implementem una varietat de mesures de seguretat per mantenir la seguretat de la vostra informació personal quan introduïu, envieu o accediu a la vostra informació personal. No obstant això, recordeu que cap mètode de transmissió a través d'Internet o mètode d'emmagatzematge electrònic és 100% segur.",
    subtitle5: '4. Compartir la vostra Informació',
    parraf5:
      "No venem, intercanviem ni transferim a tercers la vostra informació d'identificació personal. Això no inclou tercers de confiança que ens ajuden a operar el nostre programari, dur a terme el nostre negoci, o atendre-us, sempre que aquestes parts acordin mantenir aquesta informació confidencial. També podem divulgar la vostra informació quan creiem que la divulgació és apropiada per complir amb la llei, fer complir les nostres polítiques del lloc, o protegir els nostres drets, propietat o seguretat.",
    subtitle6: '5. Els vostres Drets',
    parraf6:
      'Teniu dret a accedir, rectificar, cancel·lar i oposar-vos al tractament de les vostres dades personals. Per exercir aquests drets, podeu posar-vos en contacte amb nosaltres a través del correu electrònic info@idoose.com o enviant una carta a la nostra adreça: Carrer Castell de Moratalla 45, Lorquí, Múrcia, CP 30564.',
    subtitle7: '6. Canvis en aquesta Política de Privacitat',
    parraf7:
      "Ens reservem el dret d'actualitzar aquesta Política de Privacitat en qualsevol moment. Us notificarem qualsevol canvi mitjançant la publicació de la nova Política de Privacitat en aquesta pàgina. Es recomana revisar aquesta Política de Privacitat periòdicament per estar informat de qualsevol canvi.",
    subtitle8: '7. Contacte',
    parraf8:
      'Si teniu alguna pregunta sobre aquesta Política de Privacitat, podeu posar-vos en contacte amb nosaltres:IDOOSE ROBOTIK SL Carrer Castell de Moratalla 45, Lorquí, Múrcia, CP 30564 Correu electrònic: info@idoose.com',
  },
  payments: {
    title: 'Resum de compra',
    totalFacturado: 'Total Facturat',
    taxes:'Més impostos aplicables',
    compra: 'Comprar',
    securitycard: 'Pagament segur amb targeta de crèdit',
    encrypted: 'Aquest és un pagament segur xifrat SSL de 128 bits',
    subscription: 'Subscripció',
    subrealizada:"Subscripció al pla amb èxit!",
    manage:'Gestiona la teva informació de facturació',
    mes:'/ Mes'
  },
};

export default cat;
