import PropTypes from 'prop-types';

import { useTranslate } from 'src/locales';


import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';



// ----------------------------------------------------------------------

export default function PaymentSummary({ sx, producto,products, onclick, ...other }) {
  console.log(producto)
  const { t } = useTranslate();
  const renderPrice = (
    <Stack direction="row" justifyContent="flex-end" >
      <Typography variant="h4">€</Typography>
      <Typography variant="h2">{producto.unit_amount != null ? (producto.unit_amount / 100) : '0'}</Typography>
      <Typography
        component="span"
        sx={{
          alignSelf: 'center',
          color: 'text.disabled',
          ml: 1,
          typography: 'body2',
        }}
      >
        {t('payments.mes')}
      </Typography>
    </Stack>
  );
  return (
    <Box
      sx={{
        p: 5,
        borderRadius: 2,
        bgcolor: 'background.neutral',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" sx={{ mb: 5 }}>
        {t('payments.title')}
      </Typography>
      <Stack spacing={2.5}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('payments.subscription')}
          </Typography>
          <Label color="error">{products.name != null ?  products.name : ''}</Label>
        </Stack>
        {renderPrice}
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">{t('payments.totalFacturado')}</Typography>
          <Typography variant="subtitle1">{producto.unit_amount != null ? (producto.unit_amount / 100) : '0'} €</Typography>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>
      <Typography component="div" variant="caption" sx={{ color: 'text.secondary', mt: 1 }}>
      {t('payments.taxes')}
      </Typography>
      <Button fullWidth size="large" variant="contained" onClick={onclick} sx={{ mt: 5, mb: 3 }} disabled={Array.isArray(producto) && producto.length === 0}>
      {t('payments.compra')}
      </Button>
      <Stack alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="solar:shield-check-bold" sx={{ color: 'success.main' }} />
          <Typography variant="subtitle2">{t('payments.securitycard')}</Typography>
        </Stack>
        <Typography variant="caption" sx={{ color: 'text.disabled', textAlign: 'center' }}>
        {t('payments.encrypted')}
        </Typography>
      </Stack>
    </Box>
  );
}

PaymentSummary.propTypes = {
  sx: PropTypes.object,
  producto: PropTypes.array,
  products: PropTypes.array,
  onclick: PropTypes.func.isRequired
};
